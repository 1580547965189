@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');

@font-face {
    font-family: 'GmarketSansMedium';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
}

@font-face {
    font-family: 'paybooc-Light';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/paybooc-Light.woff') format('woff');
}
@font-face {
    font-family: 'paybooc-Medium';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/paybooc-Medium.woff') format('woff');
}
@font-face {
    font-family: 'paybooc-Bold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/paybooc-Bold.woff') format('woff');
}
@font-face {
    font-family: 'paybooc-ExtraBold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/paybooc-ExtraBold.woff') format('woff');
}

#carmon-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.carmon-view-container {
    flex: 1;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    margin:0 !important;
}



.carmon-footer {
    line-height: 2.5;
    /*padding: 1em;*/
    /*margin: 1em 0;*/
}

.carmon-footer * {
    color: #878890;
}

.license-number-plate-outer {
    padding: 2px;
    border: 2px solid #cccccc;
    border-radius: 6px;
    background-color: #ffffff;
}

.license-number-plate-inner {
    padding: 8px 2px;
    border: 2px solid #666;
    background-color:transparent;
    border-radius: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}

#license-number-plate-text {
    flex: 1;
    font-size: clamp(1.6rem, 5vw, 3.2rem);
    line-height:1.5em;
    font-weight: bolder;
    padding: 0 20px !important;
    border: 0;
    background-color: transparent !important;
    text-align: center;
}

.license-number-plate-inner-nav {
    padding: 0px;
    border: 2px solid #666;
    background-color:transparent;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}

#license-number-plate-text-nav {
    flex: 1;
    font-size: clamp(1rem, 2.5vw, 2rem);
    line-height: 2em;
    font-weight: bolder;
    padding: 0 25px !important;
    border: 0;
    background-color:transparent;
}

.owner-name-outer {
    padding: 2px;
    border: 2px solid #cccccc;
    border-radius: 6px;
    background-color: #ffffff;
}

.owner-name-inner {
    padding: 8px 2px;
    border: 0px solid #333333;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#owner-name, #purchase-price {
    border-radius: 10px;
    font-size: 2em;
    border: 2px solid #666;
}

#owner-name-text {
    flex: 1;
    font-size: clamp(1.6rem, 5vw, 3.2rem);
    font-weight: bolder;
    padding: 0 !important;
    border: 0;
    text-align: center;
}

#searchText, #contactText {
    font-weight: bolder;
}

.overlay {
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
}

@keyframes car-bounce {
    0% {margin-top:0px;}
    50% {margin-top:5px;}
    100% {margin-top:0px;}
}

.swiper-slide-active .caption {
    width: calc(100% - 24px);
    height: 60px;
    margin-top: -60px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #ffffff;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 0 0 4px 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}

.news-item-caption {
    width: 100%;
}

.news-item-image {
    width: 100%;
    height: clamp(160px, 60vmin, 480px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px;
}

.news-item-caption > .title {
    width: 100%;
    color: #ffffff;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.news-item-caption > .sub-title {
    width: 100%;
    color: #ffffff !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

input#owner-name, input#purchase-price {
    padding: 10px;
}


.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    /*background: #fff;*/
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper .swiper-pagination-bullet-active {
    background-color: #000;
}

.bg-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.15;
}

.bg-video:after {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.button {
    font-family: 'paybooc-Light', serif !important;
}